import React from 'react';
import '../css/RAuction.css'; // Ensure your CSS file path is correct
import AuctionDetails from '../components/AuctionDetails';

const RAuction = () => {
  return (
    <>
    <section className='rauction' id="rauction">
      <div className="landing-container">
        <div className="main-section">
          <div className='padded-15'>
            <div className="auc-overlay"> {/* Changed from 'text-background' for better naming */}
              <p className="auc-title">Energy <br></br><b>Reverse Auctions</b></p>
              <p className="auc-subtitle"><b>Using our reverse auction, we help businesses, schools, and all types of organizations procure the lowest possible electricity and natural gas rates. Simply put: when suppliers compete, you win. </b></p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div className='spacer'></div>
    <div className='explanation padded-15'>
      <p className='explanation-title'>Reverse Auctions</p>
      <p className='explanation-title'>make energy procurement</p>
      <p className='explanation-title bolding'><b>simple, transparent, and cost-effective.</b></p>
      <p className='explanation-subtitle'> <b>Getting the best energy pricing should always be a fair and straightforward process.</b></p>
      <div class="grid-container">
        <div class="grid-item"><img className='check' src="check.png"/>We gather all of the competitive suppliers in your market to compete for your business</div>
        <div class="grid-item"><img className='check' src="check.png"/>To help you make decisions, every auction comes with a detailed summary and cost comparison analysis </div>
        <div class="grid-item"><img className='check' src="check.png"/>Participating in a reverse auction is free - so you'll never have to worry about out-of-pocket expenses </div>
        <div class="grid-item"><img className='check' src="check.png"/>Because we know your time is valuable, auctions last for only approximately 5-10 minutes </div>
      </div>
    </div>
    <div className='orange-background'></div>
    <div className='auc-works padded-15'>
      <p className='auc-works-title'>How a <b>Reverse Auction</b> works</p>
      <p className='auc-works-subtitle'>Get real-time offers from the nation's top energy suppliers</p>
      <div className='auc-works-benefits'>
        <div className='auc-works-benefits-box'>
          <div className='auc-works-benefits-box-header'>
            <img src='hand-click.svg'></img>
            <p className='auc-works-benefits-box-header-title'><b>Get Registered</b></p>
          </div>
          <p className='auc-works-benefits-box-text'>Simply provide us with a few details about your organization and energy usage, and we will get to work on your organization's behalf.</p>
        </div>
        <div className='auc-works-benefits-box'>
          <div className='auc-works-benefits-box-header'>
            <img src='event-offer.svg'></img>
            <p className='auc-works-benefits-box-header-title'><b>Schedule Your Auction</b></p>
          </div>
          <p className='auc-works-benefits-box-text'>Schedule your reverse auction on the day and time that's convenient for you. We'll invite all the leading suppliers in your area to compete for your electricity or natural gas business.</p>
        </div>
        <div className='auc-works-benefits-box'>
          <div className='auc-works-benefits-box-header'>
            <img src='analysis.svg'></img>
            <p className='auc-works-benefits-box-header-title'><b>Get Supplier Offers</b></p>
          </div>
          <p className='auc-works-benefits-box-text'>Watch as suppliers make real-time offers and counter-offers to earn your business. If you have questions, an energy analyst will be there to guide you through the auction.</p>
        </div>
        <div className='auc-works-benefits-box'>
          <div className='auc-works-benefits-box-header'>
            <img src='hand-coin.svg'></img>
            <p className='auc-works-benefits-box-header-title'><b>Evaluate Your Offers</b></p>
          </div>
          <p className='auc-works-benefits-box-text'>Receive a detailed savings analysis after the auction. If you're ready to move forward, an energy analyst can help you select a preferred supplier.</p>
        </div>
      </div>
    </div>
    <div className='spacer'></div>
    <AuctionDetails />
    <div className='spacer'></div>
    <div className='spacer'></div>
    </>
  );
};

export default RAuction;
