import React, { useState } from 'react';
import '../css/Industry.css';

const CategoryButtons = ({ categories, onCategoryClick }) => (
  <div className="category-buttons">
    {categories.map((category) => (
      <button
        key={category}
        onClick={() => onCategoryClick(category)}
        className="category-button"
      >
        {category}
      </button>
    ))}
  </div>
);

const BackgroundImage = ({ activeCategory, onCategoryClick }) => {
  const imageMap = {
    'Small Business': 'business',
    'Commercial & Industrial': 'industry',
    'Education': 'education',
    'Non-Profit': 'nonprofit',
    'Hospitality': 'hospitality',
  };
  const categoryDescriptions = {
    'Small Business': 'Lower your long-term energy costs and stabilize your energy budget with our advanced energy solutions.',
    'Commercial & Industrial': 'Operate more efficiently by securing long-term energy savings through our customized energy services. ',
    'Education': 'Reduce operational expenses and overcome budget challenges by leveraging our tailored energy solutions.',
    'Non-Profit': 'Get simple, streamlined energy procurement solutions that help you reach your financial goals and focus on achieving your mission.',
    'Hospitality': 'Discover how our reverse auction and energy solutions enable budget flexibility for the hospitality industry. ',
  };
  
  const backgroundImage = imageMap[activeCategory] || 'default-image';
  const categoryText = categoryDescriptions[activeCategory];

  return (
    <div className={`container`}>
      <div className="category-header">
        {Object.keys(imageMap).map((category) => (
          <button
            key={category}
            onClick={() => onCategoryClick(category)}
            className={`category-button ${category === activeCategory ? 'active' : ''}`}
          >
            {category}
          </button>
        ))}
      </div>
      <div className={`background-image ${backgroundImage}`}>
        <div className='category'>
          <div className='category-title'>{activeCategory}</div>
          <div className="category-text">{categoryText}</div>
        </div>
      </div>
    </div>
  );
};


const Industry = () => {
  const [activeCategory, setActiveCategory] = useState('Commercial & Industrial');
  const categories = ['Small Business', 'Commercial & Industrial', 'Education', 'Non-Profit', 'Hospitality'];

  const handleCategoryClick = (category) => {
    setActiveCategory(category);
  };

  return (
    <BackgroundImage activeCategory={activeCategory} onCategoryClick={handleCategoryClick}>
      <CategoryButtons
        categories={categories}
        onCategoryClick={handleCategoryClick}
      />
    </BackgroundImage>
  );
};

export default Industry;
