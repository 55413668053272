import React from 'react';
import '../css/About.css'; // Ensure your CSS file path is correct
import { Link } from 'react-router-dom';

const About = () => {
  return (
    <>
    <section className='about' id="about">
      <div className="landing-container">
        <div className="main-back">
          <div className='padded'>
            <div className="main-overlay"> {/* Changed from 'text-background' for better naming */}
              <p className="main-title">Energy <b>Procurement</b></p>
              <p className="main-subtitle"><b>A streamlined, economical approach to procuring electricity and natural gas</b></p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div className='spacer'></div>
    <div className='explain padded-25'>
      <p className='explain-title'>Energy Procurement</p>
      <h2 className='explain-subtitle'>Simple Cost-Effective Solutions</h2>
      <p className='explain-subsubtitle'><b> We help organizations of all sizes to discover the best rates on energy through our unique energy procurement process. </b></p>
      <p className='explain-text'> Between energy market fluctuations and the changing needs of your organization, procuring energy at the lowest rate can be a challenge. However, we work to simplify this once complicated chore. </p>
      <p className='explain-text'> We work on your behalf to cut through the complexity of energy procurement to bring you the best energy rates available from the nation's top suppliers.</p>
      <p className='explain-text'> With us as your energy procurement partner, you'll gain access to a vast network of energy suppliers, highly competitive energy pricing, and the strategic insight to reduce your long-term energy risks. </p>
      <div className='spacer-mini'></div>
      <div className='explain-box'>
        <div className='explain-box-item'>
          <img className='bullet' src='dot-sign.svg'/>
          <p>Unique Supplier Rates</p>
        </div>
        <div className='explain-box-item'>
          <img className='bullet' src='dot-sign.svg'/>
          <Link to="/rauction" className='linked'><p>Reverse Auction</p></Link>
        </div>
        <div className='explain-box-item'>
          <img className='bullet' src='dot-sign.svg'/>
          <p>Customized Energy Quotes</p>
        </div>
      </div>
    </div>
    <div className='spacer'></div>
    <div className='spacer'></div>
    </>
  );
};

export default About;
