import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../css/Header.css';

const Header = () => {
  const [scrolled, setScrolled] = useState(false);
  const navigate = useNavigate();

  const handleScroll = () => {
    const offset = window.scrollY;
    setScrolled(offset > 50);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header className={scrolled ? "header scrolled" : "header"}>
      <img src='snbenergy.png' alt="SNB Energy Logo" className="logo" onClick={() => navigate('/')} />
      <nav className="nav-menu">
        <Link to="/about" className="nav-link nav-item">What We Do</Link>
        <Link to="/rauction" className="nav-link nav-item">Reverse Auction</Link>
        <div className="nav-item">
          <Link to="/industries" className="nav-link">Industries We Serve</Link>
          <div className="dropdown-content">
            <Link to="/industries/small-business" className="dropdown-item">Small Business</Link>
            <Link to="/industries/commercial" className="dropdown-item">Commercial & Industrial</Link>
            <Link to="/industries/education" className="dropdown-item">Education</Link>
            <Link to="/industries/non-profit" className="dropdown-item">Non-Profit</Link>
            <Link to="/industries/hospitality" className="dropdown-item">Hospitality</Link>
          </div>
        </div>
        <Link to="/contact" className="nav-link nav-item">Contact</Link>
      </nav>
    </header>
  );
};

export default Header;
