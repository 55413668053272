import React from 'react';
import '../../css/RAuction.css'; // Ensure your CSS file path is correct

const Commercial = () => {
  return (
    <>
    <section className='rauction' id="rauction">
      <div className="landing-container">
        <div className="main-section">
          <div className='padded-15'>
            <div className="auc-overlay"> {/* Changed from 'text-background' for better naming */}
              <p className="auc-title">Commercial & Industrial</p>
              <p className="auc-subtitle"><b>A full range of easily manageable, cost-effective energy solutions tailored for your industry.</b></p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div className='spacer'></div>
    <div className='explanation padded-15'>
      <p className='explanation-title'>Long Term</p>
      <p className='explanation-title bolding'><b>Energy Solutions</b></p>
      <p className='explain-text-commercial padded-15'> Between energy market fluctuations and the changing needs of your organization, procuring energy at the lowest rate can be a challenge. However, we work to simplify this once complicated chore. </p>
      <p className='explain-text-commercial padded-15'> We work on your behalf to cut through the complexity of energy procurement to bring you the best energy rates available from the nation's top suppliers.</p>
      <p className='explain-text-commercial padded-15'> With us as your energy procurement partner, you'll gain access to a vast network of energy suppliers, highly competitive energy pricing, and the strategic insight to reduce your long-term energy risks. </p>
    </div>
    <div className='spacer'></div>
    <div className="advertised-section-commercial padded-15">
          <div className="advertised-text-commercial">
            <h2>SERVING THESE LOCATIONS</h2>
            {/* You can add more text or elements here */}
          </div>
          <div className="advertised-map-commercial">
            <img src='map.png' alt="Advertised States" />
          </div>
    </div>
    <div className='spacer'></div>
    <div className='spacer'></div>
    </>
  );
};

export default Commercial;
