import React from 'react';
import '../../css/RAuction.css'; // Ensure your CSS file path is correct

const Hospitality = () => {
  return (
    <>
    <section className='rauction' id="rauction">
      <div className="landing-container">
        <div className="main-section">
          <div className='padded-15'>
            <div className="auc-overlay"> {/* Changed from 'text-background' for better naming */}
              <p className="auc-title">Hospitality</p>
              <p className="auc-subtitle"><b>Streamlined energy savings that enable greater investments in guest and client experiences. </b></p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div className='spacer'></div>
    <div className='explanation padded-15'>
      <p className='explanation-title'>Long Term</p>
      <p className='explanation-title bolding'><b>Energy Solutions</b></p>
      <p className='explain-text-commercial padded-15'> As you know, hospitality is a highly competitive industry, where owners and management are always seeking new approaches to deliver more services at a superior price point to guests. But with the costs of operations and labor increasing year after year, finding ways to introduce greater amenities with dwindling budgets is demanding.  </p>
      <p className='explain-text-commercial padded-15'> To assist your organization with managing its operational costs more efficiently, we are proud to offer a Reverse Auction, which is designed to reduce your costs of operation and help you stay competitive. </p>
      <p className='explain-text-commercial padded-15'> The Reverse Auction uses a proprietary supplier bidding strategy to uncover the best electricity and gas rates in your market. Once your auction ends, you can choose your preferred rates and terms, and our experts will help you lock in the contract. </p>
    </div>
    <div className='spacer'></div>
    <div className='spacer'></div>
    <div className='spacer'></div>
    </>
  );
};

export default Hospitality;
