import React from 'react';
import ContactForm from '../components/ContactForm';

const Contact = () => {
  return (
    <div className="contact-page">
      <h1 className="contact-heading">Get in Touch</h1>
      <p className="contact-description">
        Have a question or need assistance? Please fill out the form below, and
        we'll get back to you as soon as possible.
      </p>
      <ContactForm />

      <style jsx>{`
        .contact-page {
          max-width: 800px;
          margin: 4rem auto;
          padding: 2rem;
        }

        .contact-heading {
          font-size: 2.5rem;
          font-weight: 700;
          text-align: center;
          margin-bottom: 1rem;
          color: #333;
        }

        .contact-description {
          font-size: 1.2rem;
          text-align: center;
          margin-bottom: 2rem;
          color: #666;
        }
      `}</style>
    </div>
  );
};

export default Contact;