import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for button click navigation
import '../css/Landing.css'; // Ensure your CSS file path is correct
import ContactForm from '../components/ContactForm';
import Industry from '../components/Industry';

const Landing = () => {
  const navigate = useNavigate();

  return (
    <div className="landing-container">
      <div className="hero-section">
        <div className='padded'>
          <div className="hero-overlay"> {/* Changed from 'text-background' for better naming */}
            <p className="hero-title">Energy Procurement</p>
            <h3 className="hero-title">Made Simple</h3>
            <div className='contact-landing'>
              <button className="cta-button" onClick={() => navigate('/contact')}>CONTACT US</button>
              <div className='contact-info'>
                {/* icon for contact */}
                <div className='contact-text-box'>
                  <p className='contact-text'>Call Us Today</p>
                  <p className='contact-text'>+1 406-546-5968</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Industry />
      <div className='padded'>
        <div className="info-section">
          <h1>SNB Energy</h1>
          <p>is an independent full-service energy consulting company providing energy services to all types of businesses across the country...</p>
        </div>
      </div>
      <div className="advertised-section padded">
          <div className="advertised-text">
            <p>SERVING THESE LOCATIONS</p>
            {/* You can add more text or elements here */}
          </div>
          <div className="advertised-map">
            <img src='map.png' alt="Advertised States" />
          </div>
      </div>
      <ContactForm />
    </div>
  );
};

export default Landing;
