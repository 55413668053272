import React from 'react';
import '../../css/RAuction.css'; // Ensure your CSS file path is correct

const NonProfit = () => {
  return (
    <>
    <section className='rauction' id="rauction">
      <div className="landing-container">
        <div className="main-section">
          <div className='padded-15'>
            <div className="auc-overlay"> {/* Changed from 'text-background' for better naming */}
              <p className="auc-title">Non-Profit <br></br><b>Organizations</b></p>
              <p className="auc-subtitle"><b>Our energy procurement solutions give you the flexibility to invest in your mission - and meet your financial goals. </b></p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div className='spacer'></div>
    <div className='explanation padded-15'>
      <p className='explanation-title'>Powering Your <b>True Purpose</b></p>
      <p className='explain-text-commercial padded-15'> As a non-profit, your ability to change the world is often limited by your budget. When operational costs are high, expanding your non-profit's reach and fulfilling your purpose becomes more difficult.</p>
      <p className='explain-text-commercial padded-15'> With our technology, you'll gain access to energy experts, valuable resources, and simple account management tools that make energy procurement easier and more economical than ever before. Combined with our proven experience in helping non-profits achieve their financial and operational goals, we serve as a true energy partner for your organization. </p>
      <p className='explain-text-commercial padded-15'> Through our Reverse Auction, we deliver a simple, straightforward approach to reducing your operational expenses - enabling you to put your time and focus towards building on your mission and vision.  </p>
    </div>
    <div className='spacer'></div>
    <div className='spacer'></div>
    <div className='spacer'></div>
    </>
  );
};

export default NonProfit;
