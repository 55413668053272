import React, { useState } from 'react';
import '../css/Industry.css';

const CategoryButtons = ({ categories, onCategoryClick }) => (
  <div className="category-buttons">
    {categories.map((category) => (
      <button
        key={category}
        onClick={() => onCategoryClick(category)}
        className="category-button"
      >
        {category}
      </button>
    ))}
  </div>
);

const BackgroundImage = ({ activeCategory, onCategoryClick }) => {
  const imageMap = {
    'No Obligations': 'obligation',
    'Live Auction Replays': 'replay',
    'Full-Market Coverage': 'coverage',
    'Equal Terms': 'equalterms',
  };
  const categoryDescriptions = {
    'No Obligations': 'After the auction, you can lock in your new energy pricing right away for up to 5 years. Or simply wait for another time. The choice is yours.',
    'Live Auction Replays': 'We will provide you with a video recording of your auction event to ensure compliance for any regulatory audits.',
    'Full-Market Coverage': 'Our partnerships with leading energy suppliers gives you access to an extensive network ready to compete for your business.',
    'Equal Terms': 'We ensure that suppliers competing for your business offer identical energy products - meaning no post-auction surprises. ',
  };

  const backgroundImage = imageMap[activeCategory] || 'default-image';
  const categoryText = categoryDescriptions[activeCategory];

  return (
    <div className={`container`}>
      <div className="category-header">
        {Object.keys(imageMap).map((category) => (
          <button
            key={category}
            onClick={() => onCategoryClick(category)}
            className={`category-button ${category === activeCategory ? 'active' : ''}`}
          >
            {category}
          </button>
        ))}
      </div>
      <div className={`background-image ${backgroundImage}`}>
          <div className='category'>
            <div className='category-title'>{activeCategory}</div>
            <div className="category-text">{categoryText}</div>
          </div>
      </div>
    </div>
  );
};


const AuctionDetails = () => {
  const [activeCategory, setActiveCategory] = useState('No Obligations');
  const categories = ['No Obligations', 'Live Auction Replays', 'Full-Market Coverage', 'Equal Terms'];

  const handleCategoryClick = (category) => {
    setActiveCategory(category);
  };

  return (
    <BackgroundImage activeCategory={activeCategory} onCategoryClick={handleCategoryClick}>
      <CategoryButtons
        categories={categories}
        onCategoryClick={handleCategoryClick}
      />
    </BackgroundImage>
  );
};

export default AuctionDetails;
