import React from 'react';
import '../css/Industries.css'; // Ensure your CSS file path is correct

const Industries = () => {
  return (
    <>
    <section id="industries" className='industries'>
      <h2>Industries We Serve</h2>
      <p>This is a brief description of our website.</p>
    </section>
    <div className='spacer'></div>
    <div className='spacer'></div>
    <div className='spacer'></div>
    <div className='spacer'></div>
    <div className='spacer'></div>
    <div className='spacer'></div>
    <div className='spacer'></div>
    <div className='spacer'></div>
    <div className='spacer'></div>
    </>
  );
};

export default Industries;
