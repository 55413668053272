import React from 'react';
import '../../css/RAuction.css'; // Ensure your CSS file path is correct

const SmallBusiness = () => {
  return (
    <>
    <section className='rauction' id="rauction">
      <div className="landing-container">
        <div className="main-section">
          <div className='padded-15'>
            <div className="auc-overlay"> {/* Changed from 'text-background' for better naming */}
              <p className="auc-title">Small Business</p>
              <p className="auc-subtitle"><b>Streamlined solutions built to maximize your energy savings</b></p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div className='spacer'></div>
    <div className='explanation padded-15'>
      <p className='explanation-title'>Powering Your</p>
      <p className='explanation-title bolding'>Business Forward</p>
      <p className='explain-text-commercial padded-15'> Whether you're a small business or large-scale enterprise, you understand the value of keeping operational costs low. We understand that when you're running the day-to-day of your business, discovering actionable, cost-effective solutions can be challenging. </p>
      <p className='explain-text-commercial padded-15'> We take the guesswork out of reducing your company's energy expenses with true cost-cutting solutions. Through our vast supplier network and energy procurement technology, we build a roadmap to maximize your savings and protect your business against market risks and uncertainty. </p>
      <p className='explain-text-commercial padded-15'> As your other business expenses continue to rise, you can count on us to find you the lowest energy rates available in your market, so you can invest more of your time - and your capital - on building your organization. </p>
    </div>
    <div className='spacer'></div>
    <div className='spacer'></div>
    <div className='spacer'></div>
    </>
  );
};

export default SmallBusiness;
