import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import Landing from './pages/Landing'; // This would be your new home page
import About from './pages/About';
import Contact from './pages/Contact';
import Industries from './pages/Industries';
import RAuction from './pages/RAuction';
import Footer from './components/Footer';
import SmallBusiness from './pages/industries/SmallBusiness'; 
import Commercial from './pages/industries/Commercial'; 
import Education from './pages/industries/Education'; 
import NonProfit from './pages/industries/NonProfit'; 
import Hospitality from './pages/industries/Hospitality'; 
import ScrollToTop from './components/ScrollToTop'; // Adjust the path as needed



function App() {
  return (
    <Router>
      <div className="App">
        <ScrollToTop />
        <Header />
        <main>
          <Routes>
            <Route path="/" element={<Landing />} exact />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/industries" element={<Industries />} />
            <Route path="/rauction" element={<RAuction />} />
            <Route path="/industries/small-business" element={<SmallBusiness />} />
            <Route path="/industries/commercial" element={<Commercial />} />
            <Route path="/industries/education" element={<Education />} />
            <Route path="/industries/non-profit" element={<NonProfit />} />
            <Route path="/industries/hospitality" element={<Hospitality />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
