import React from 'react';
import '../css/Footer.css'; // Ensure you have the correct path

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section footlogo">
          <img className='footer-logo' src="snbenergy.png" alt="SNB Energy Logo"/>
        </div>
        <div className="footer-section">
          <h3>SNB Energy</h3>
          <ul>
            <li>What We Do</li>
            <li>Reverse Auction</li>
            <li>Contact Us</li>
          </ul>
        </div>
        <div className="footer-section">
          <h3>CONTACT US</h3>
          <p>406-546-5968</p>
          <p>user@snbenergy.com</p>
        </div>
      </div>
      <div className="footer-bottom">
        © 2024 SNB ENERGY. ALL RIGHTS RESERVED.
      </div>
    </footer>
  );
};

export default Footer;
