import React from 'react';
import '../../css/RAuction.css'; // Ensure your CSS file path is correct

const Education = () => {
  return (
    <>
    <section className='rauction' id="rauction">
      <div className="landing-container">
        <div className="main-section">
          <div className='padded-15'>
            <div className="auc-overlay"> {/* Changed from 'text-background' for better naming */}
              <p className="auc-title">Education</p>
              <p className="auc-subtitle"><b>Helping schools deliver better educational experiences through lower energy expenses.</b></p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div className='spacer'></div>
    <div className='explanation padded-15'>
      <p className='explanation-title'>Energy Built</p>
      <p className='explanation-title bolding'><b>for Your Budget</b></p>
      <p className='explain-text-commercial padded-15'> From budget cuts to operational cost increases, today's schools often face difficult financial decisions and challenges. To ensure more funding goes towards programs that make a difference in the lives of students and faculty, we offer a unique platform designed to reduce your operational expenses for the long term, so you can focus on what matters most. </p>
      <p className='explain-text-commercial padded-15'> Leveraging our Reverse Auction, we can help your school uncover the most cost-effective energy solution that meets your energy budgetary requirements.</p>
      <p className='explain-text-commercial padded-15'> As an established energy partner for educational institutions nationwide, we have the experience and expertise to manage your needs with simple, transparent energy solutions. </p>
      <p className='explain-text-commercial padded-15'> Whether you're a public school district, private school, college, or university, our longstanding connections with energy suppliers enables us to provide services tailored specifically to your organization. </p>
    </div>
    <div className='spacer'></div>
    <div className='spacer'></div>
    <div className='spacer'></div>
    </>
  );
};

export default Education;
